import React from "react"
import Layout from "../components/layout"

// import "../fontawesome/css/all.min.css"
import tractor1 from "../images/tractor1.jpg"
import tractor2 from "../images/tractor2.jpg"
import tractor3 from "../images/tractor3.jpg"
import "./index.css"

const Tractorshow = () => (
  <div className="App">
    <Layout>

            <div className="container">
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1>Annual Tractor Show</h1>
            <p>
              Same day as Pardeeville Carshow in the North East corner of Chandler
              Park
            </p>
            <p>
              Dust that ‘OLD GIRL’ and join us for a day of great fun and stories of
              the past. Register your tractor the day of the show. Gates open at 8 am.
            </p>
            <p>
              For more information, call Mike Bradley 608- 429-2892 or
              608-617-6543-cell or Lavern (Choo Choo) Hying at 608-429-3447 or
              608-697-6645.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img src={tractor1} alt="Red Tractor Show" />
          </div>
          <div className="col-md-4">
            <img src={tractor2} alt="Yummy Tractor Show Food" />
          </div>
          <div className="col-md-4">
            <img src={tractor3} alt="Green Tractor Show" />
          </div>
        </div>
      </div>

      </div>
    </Layout>
  </div>
)
export default Tractorshow
